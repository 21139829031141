import './styles/wpc-map.css';
import './styles/sidebar.css';
import './styles/jstree.css';
import './fontawesome/css/all.css'
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import $ from 'jquery';

$.getJSON('./public/config.json')
  .then(config=>{
    console.log(config)

    document.title = config.title
    $("#logoimg").attr("src","./public/" + config.logo);
    
    import('./lib/main').then(function(page) {
      // Render page
      page.loadDefinition(config)
      document.getElementById('loader-page').classList.add('hidden');
    })
  })
